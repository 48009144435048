(<template>
  <landing-wrapper class="auth-page">
    <div class="auth-page__content-wrapper">
      <div class="auth-page__content-cont"
           :class="{'auth-page__content-cont--android': isDownloadApkFile}">
        <router-view />
      </div>
    </div>
  </landing-wrapper>
</template>)

<script>
  import LandingWrapper from '@/components/landing/LandingWrapper';

  export default {
    components: {
      'landing-wrapper': LandingWrapper
    },
    computed: {
      isDownloadApkFile() { return this.$route.path.includes('app_android'); }
    },
    created() {
      this.$store.commit('GlobalStore/stopProgress');
    }
  };
</script>

<style scoped>
  .auth-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: 60px;
    background-color: #f4f5f7;
  }

  .auth-page__content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
    background-color: #f2f2f2;
  }

  .auth-page__content-cont {
    display: block;
    align-self: flex-start;
    width: 100%;
    max-width: 555px;
    background-color: #fff;
  }

  #app.salita-facelifted .auth-page__content-cont {
    border-radius: 8px;
    box-shadow: var(--shadow-and-blur);
  }

  .auth-page__content-cont--android {
    max-width: 700px;
  }

  @media (max-width: 1024px) {
    .auth-page {
      padding-top: 45px;
    }
  }

  @media (max-width: 767px) {
    .auth-page__content-wrapper {
      padding: 0;
    }

    .auth-page__content-cont {
      align-self: stretch;
      max-width: initial;
      padding-top: 30px;
    }
  }
</style>
